import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
// import SaveDraft from '@/core/components/project/SaveDraft.vue';
import HeaderMenuSlot from '@/core/components/slots/HeaderMenuSlot.vue';
import HeaderMenuItem from '@/core/components/ui/HeaderMenuItem.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';
const SCHEDULE_AND_ESTIMATE_ROUTE = 'estimate-schedule';
const SCHEDULE_ROUTE = 'schedule';
const ESTIMATE_ROUTE = 'estimate';
export default defineComponent({
    components: {
        HeaderMenuSlot,
        HeaderMenuItem,
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(PROJECTS_STORE, ['hasGetProjectFinished', 'isProjectReadyToWork', 'currentSelectedProject']),
        ...mapGetters(USERS_STORE, ['user', 'wsRole', 'isWsAdmin']),
        hasQuote() {
            return this.currentSelectedProject?.projectQuoteCount;
        },
        hideSchedule() {
            if (!this.isProjectReadyToWork) {
                return true;
            }
            return false;
        },
        hideEstimateFormAndEstimateSelect() {
            if (this.isProjectReadyToWork) {
                return true;
            }
            return false;
        },
        canCreate() {
            const { wsRole, isWsAdmin } = this;
            let show = true;
            if (wsRole && !isWsAdmin) {
                show = !show;
            }
            return show;
        },
    },
    data() {
        return {
            menus: [
                {
                    name: 'overview',
                    icon: 'details-menu-icon.svg',
                    menuName: 'Details',
                },
                {
                    name: 'estimate-form',
                    icon: 'estimate-icon.svg',
                    menuName: 'Estimate',
                    isHide: this.hideEstimateFormAndEstimateSelect
                },
                {
                    name: 'estimate-schedule',
                    icon: 'schedule-icon.svg',
                    menuName: 'Schedule',
                },
                {
                    name: 'estimates',
                    icon: 'estimate-icon.svg',
                    menuName: 'Estimates',
                    isHide: this.hideEstimateFormAndEstimateSelect
                },
                {
                    name: 'attachments',
                    icon: 'clip-icon.svg',
                    menuName: 'Attachments'
                },
                {
                    name: 'members',
                    icon: 'members-icon.svg',
                    menuName: 'Members'
                },
                {
                    name: 'chat',
                    icon: 'chat-menu-icon.svg',
                    menuName: 'Chat'
                },
            ],
            SCHEDULE_ROUTE
        };
    },
    methods: {
        routeTo(event) {
            if (event === SCHEDULE_AND_ESTIMATE_ROUTE || event === SCHEDULE_ROUTE || event === ESTIMATE_ROUTE) {
                const { currentSelectedProject } = this;
                const params = {
                    projectRefId: currentSelectedProject?.refId,
                    quoteId: currentSelectedProject?.confirmedQuote?.id
                };
                this.$router.push({ name: event, params });
            }
            else {
                this.$router.push({ name: event });
            }
        },
    }
});
